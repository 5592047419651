<template>
  <v-container fluid>
    <v-row>
      <div class="text-sm-h4 px-3 mb-3">
        {{ $t('carte-visite', {prenom: vendeur.prenom, nom: vendeur.nom_famille}) }}
      </div>
    </v-row>
    <v-row>
      <CarteVisite
        :contact="vendeur"
        :fonction="vendeur.fonction"
        :from-annuaire="true"
      />
    </v-row>
  </v-container>
</template>

<script>

import CarteVisite from '@/components/contact/CarteVisite'

import { mapActions } from 'vuex'

export default {
  name: 'Presentation',
  components: {
    CarteVisite
  },
  data () {
    return {
      vendeur: {}
    }
  },
  created () {
    this.loadUser()
  },
  methods: {
    ...mapActions(['getVendeurInfos']),
    loadUser () {
      this.getVendeurInfos(this.$route.params.id).then(res => {
        this.vendeur = res
      })
    }
  }
}
</script>
